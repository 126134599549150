import styled from '@emotion/styled'
import { graphql } from 'gatsby'
import React from 'react'
import { Helmet } from 'react-helmet'
import SEO from '../../components/atoms/seo'
import LpFooter01 from '../../components/organisms/lpFooter01'
import BreakPoints from '../../styles/breakPoints'
import LpExpenseHeader01 from '../../components/organisms/lp-expense/lpExpenceHeader01'
import LpFvExpense01 from '../../components/organisms/lp-expense/lpFvExpense01'
import LpExpenseCta01 from '../../components/organisms/lp-expense/lpExpenseCta01'
import LpExpenseContent01 from '../../components/organisms/lp-expense/lpExpenseContent01'
import LpExpenseContent02 from '../../components/organisms/lp-expense/lpExpenseContent02'
import LpExpenseContent03 from '../../components/organisms/lp-expense/lpExpenseContent03'
import LpExpenseContent04 from '../../components/organisms/lp-expense/lpExpenseContent04'
import LpExpenseFunction01 from '../../components/organisms/lp-expense/lpExpenseFunction01'
import LpExpenseSecurity01 from '../../components/organisms/lp-expense/lpExpenseSecurity01'
import LpExpensePlan01 from '../../components/organisms/lp-expense/lpExpensePlan01'
import LpExpenseLast01 from '../../components/organisms/lp-expense/lpExpenseLast01'
import LpExpenseContact01 from '../../components/organisms/lp-expense/lpExpenseContact01'

const Wrapper = styled.div`
  background: #ffffff;
  width: 100vw;
  overflow-x: hidden;

  .arr-down {
    display: flex;
    position: relative;
    justify-content: center;

    img {
      position: absolute;
      top: -40px;
      z-index: 1;
      width: 14px;
      margin-left: 14px;
    }
  }

  .cta-wrap {
    background: #ffffff url(/images/lp/lp-expense/lpCtaBg01.png) repeat;
    padding: 76px 0;

    .cta {
      margin-top: 0;
    }
  }

  ${BreakPoints.medium} {
    .arr-down {
      img {
        top: -20px;
        width: 12px;
      }
    }
  }
`

/**
 * キンクラLP-経費精算
 * @constructor
 */
const LpExpenseIndexPage = () => (
  <Wrapper>
    <SEO
      title="キンクラ"
      description="客先常駐特化型のクラウド勤怠管理システムならキンクラ。客先常駐、派遣での社員の勤怠をラクラク一元管理。キンクラなら働き方改革にもピッタリマッチします。"
    />
    <Helmet>
      <title>
        キンクラ | 経費精算も勤怠管理もラクラク一元管理 -
        テレワークをするならクラウド勤怠管理システム
      </title>
    </Helmet>
    <LpExpenseHeader01 />
    <LpFvExpense01 />
    <LpExpenseCta01 />
    <div className="arr-down">
      <img
        src="/images/lp/lp-expense/lpArr01.png"
        alt="下にスクロールしてキンクラの詳細をご覧ください"
      />
    </div>
    <LpExpenseContent01 />
    <LpExpenseContent02 />
    <LpExpenseContent03 />
    <LpExpenseContent04 />
    <div className="cta-wrap">
      <LpExpenseCta01 />
    </div>
    <LpExpenseFunction01 />
    <LpExpenseSecurity01 />
    <LpExpensePlan01 />
    <LpExpenseLast01 />
    <div className="cta-wrap">
      <LpExpenseCta01 />
    </div>
    <LpExpenseContact01 />
    <LpFooter01 />
  </Wrapper>
)

export default LpExpenseIndexPage

export const pageQuery = graphql`
  query LpExpenseIndexPage {
    site {
      siteMetadata {
        title
      }
    }
  }
`
