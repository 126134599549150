import styled from '@emotion/styled'
import { navigate } from 'gatsby-link'
import React, { ChangeEvent, useState } from 'react'
import BreakPoints from '../../../styles/breakPoints'
import StringUtil from '../../../utils/StringUtil'
import CommonCheckbox from '../../atoms/commonCheckbox'
import OfficialCommonBtn from '../../atoms/officialCommonBtn'
import { ContactKindList } from '../../../types/ContactKind'
import { IContact } from '../../../types/Contact'
import { InitContact } from '../../../pages/contact'
import useKindName from '../../../hooks/useKindName'
import useApi from '../../../hooks/useApi'
import { SiteKind, SiteKindList } from '../../../types/SiteKind'

const Wrapper = styled.section`
  width: 100%;
  padding: 80px 0;
  background: #e6e6e6;

  .p-section-inner {
    h1 {
      text-align: center;
      font-size: 30px;
      padding-bottom: 30px;
    }

    max-width: 700px;
    padding: 50px 80px;
    margin: 0 auto;
    background: #ffffff;
    border-radius: 30px;

    .p-section-contact {
      width: 100%;
      margin: 0 auto;
      text-align: center;

      .item-wrap {
        margin: 0 0 20px;

        &.name-wrap {
          display: flex;

          div {
            width: 50%;
            margin: 0;
          }
        }

        .label-area {
          display: flex;
          flex-wrap: wrap;
          padding: 0 0 2px;

          .txt-color-alert {
            margin: 0 5px;

            &.require {
              opacity: 0.6;
            }

            &.err {
              margin-left: auto;
              word-break: keep-all;
            }
          }
        }

        textarea {
          min-height: 120px;
        }
      }
    }

    .p-top-data-wrap {
      background: #f9f3c4;
      width: 100%;
      border-radius: 8px;
      margin: -30px auto 60px;
      padding: 20px;
      text-align: center;

      p {
        margin: 0 0 20px;
      }

      button {
        margin: 0;
      }
    }

    .p-sub-data-wrap {
      border: solid 2px #dbdee1;
      background: #f7fbfa;
      width: 100%;
      border-radius: 8px;
      margin: 30px auto 0;
      padding: 20px;
    }

    .p-select {
      overflow: hidden;
      text-align: center;
      position: relative;
      border: solid 2px #ebebeb;
      background: #ffffff;
      width: 100%;
      min-height: 40px;
      border-radius: 8px;
      transition: 0.3s;

      &:hover {
        border-color: #3ec7b3;
      }

      &:before {
        position: absolute;
        top: 45%;
        right: 0.8em;
        width: 0;
        height: 0;
        padding: 0;
        content: '';
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 6px solid #272d2c;
        pointer-events: none;
      }

      select {
        width: 100%;
        cursor: pointer;
        text-indent: 0.01px;
        text-overflow: ellipsis;
        border: none;
        outline: none;
        background: transparent;
        box-shadow: none;
        -webkit-appearance: none;
        appearance: none;
        padding: 8px 38px 8px 8px;

        &::-ms-expand {
          display: none;
        }
      }
    }

    .p-btn-send {
      font-size: 24px;
      height: 60px;
      padding: 0 40px;
      border: #ffffff 2px solid;
      box-shadow: 0 0 0 2px rgb(255 141 10);

      &:hover {
        border: #ffffff 2px solid;
      }
    }
  }

  ${BreakPoints.large} {
    .p-section-inner {
      .p-section-contact {
      }
    }
  }

  ${BreakPoints.medium} {
    .p-section-inner {
      padding: 30px 20px;

      .p-section-contact {
      }
    }
  }
`

/**
 * LP経費精算用問い合わせフォーム
 * @constructor
 */
const LpExpenseContact01 = () => {
  const [contact, setContact] = useState<IContact>(InitContact)
  const [isSending, setSending] = useState(false)
  const getKindName = useKindName(ContactKindList)
  const getSiteKindName = useKindName(SiteKindList)
  const { execApi } = useApi()
  const [isError, setError] = useState(false)

  /** データ変更時処理 */
  const onChange =
    (key: keyof IContact) =>
    (
      e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
    ) => {
      let val: string | boolean = e.target.value
      if (key === 'tel') val = StringUtil.pickNumberHyphen(val)
      else if (key === 'agree' && e.target instanceof HTMLInputElement)
        val = e.target.checked
      setContact({ ...contact, [key]: val })
    }

  /**
   * 送信ボタンクリック時処理
   */
  const onClickSend = () => {
    if (
      !contact.company ||
      !contact.name ||
      !contact.tel ||
      !contact.email ||
      !StringUtil.checkFormatMail(contact.email) ||
      !contact.agree ||
      !contact.contactKind ||
      !contact.body
    ) {
      setError(true)
      return
    }
    setSending(true)
    const request = {
      ...contact,
      contactKind: getKindName(contact.contactKind),
      isExpense: true,
      parameter: window.location.search,
      siteKind: getSiteKindName(SiteKind.EXPENSES),
    }
    execApi(
      '/contact',
      request,
      () => {
        navigate('/lp-expense/thanks/')
      },
      () => {
        setSending(false)
      }
    )
  }

  return (
    <Wrapper>
      <div id="lp-download" className="p-section-inner">
        <h1>お問い合わせ内容</h1>
        <div className="p-section-contact">
          {/** 会社名 */}
          <div className="item-wrap">
            <p className="label-area">
              <span className="txt-bold">会社名</span>
              <span className="txt-color-alert txt-size-small require">
                *必須
              </span>
              {isError && !contact.company && (
                <span className="txt-color-alert txt-size-small err">
                  会社名を入力してください
                </span>
              )}
            </p>
            <input
              value={contact.company}
              placeholder="○○株式会社"
              onChange={onChange('company')}
            />
          </div>
          {/** 氏名 */}
          <div className="item-wrap">
            <p className="label-area">
              <span className="txt-bold">ご担当者名</span>
              <span className="txt-color-alert txt-size-small require">
                *必須
              </span>
              {isError && !contact.name && (
                <span className="txt-color-alert txt-size-small err">
                  氏名を入力してください
                </span>
              )}
            </p>
            <input
              value={contact.name}
              placeholder="田中太郎"
              onChange={onChange('name')}
            />
          </div>
          {/** メールアドレス */}
          <div className="item-wrap">
            <p className="label-area">
              <span className="txt-bold">メールアドレス</span>
              <span className="txt-color-alert txt-size-small require">
                *必須
              </span>
              {isError && !contact.email && (
                <span className="txt-color-alert txt-size-small err">
                  メールアドレスを入力してください
                </span>
              )}
              {isError &&
                contact.email &&
                !StringUtil.checkFormatMail(contact.email) && (
                  <span className="txt-color-alert txt-size-small err">
                    メールアドレスの形式が間違っています
                  </span>
                )}
            </p>
            <input
              value={contact.email}
              placeholder="info@sample.jp"
              onChange={onChange('email')}
            />
          </div>
          {/** 電話番号 */}
          <div className="item-wrap">
            <p className="label-area">
              <span className="txt-bold">電話番号</span>
              <span className="txt-color-alert txt-size-small require">
                *必須
              </span>
              {isError && !contact.tel && (
                <span className="txt-color-alert txt-size-small err">
                  電話番号を入力してください
                </span>
              )}
            </p>
            <input
              value={contact.tel}
              placeholder="0312345678"
              onChange={onChange('tel')}
            />
          </div>
          {/** 現在の経費精算管理方法 */}
          <div className="item-wrap">
            <p className="label-area">
              <span className="txt-bold">お問い合わせ種別</span>
              <span className="txt-color-alert txt-size-small require">
                *必須
              </span>
              {isError && !contact.contactKind && (
                <span className="txt-color-alert txt-size-small err">
                  お問い合わせ種別を選択してください
                </span>
              )}
            </p>
            <div className="p-select">
              <select
                required
                value={contact.contactKind}
                onChange={onChange('contactKind')}
              >
                <option value="" hidden>
                  選択してください
                </option>
                {ContactKindList.map((val) => (
                  <option key={val.kind} value={val.kind}>
                    {val.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          {/** お問い合わせ内容 */}
          <div className="item-wrap">
            <p className="label-area">
              <span className="txt-bold">お問い合わせ内容</span>
              <span className="txt-color-alert txt-size-small require">
                *必須
              </span>
              {isError && !contact.body && (
                <span className="txt-color-alert txt-size-small err">
                  お問い合わせ内容をご記入ください
                </span>
              )}
            </p>
            <textarea
              placeholder="お問い合わせされたい具体的な内容をご記入ください"
              value={contact.body}
              onChange={onChange('body')}
            />
          </div>
          {/** プラポリ同意 */}
          <div className="txt-center">
            <CommonCheckbox
              checked={contact.agree}
              onChange={onChange('agree')}
            >
              <a href="/policy/" target="_blank">
                プライバシーポリシー
              </a>
              に同意する
            </CommonCheckbox>
            {isError && !contact.agree && (
              <span className="txt-color-alert txt-size-small err">
                プライバシーポリシーに同意してください。
              </span>
            )}
          </div>
          {/** 送信する */}
          <OfficialCommonBtn
            size="large"
            level="strong"
            className="p-btn-send"
            onClick={onClickSend}
            disabled={isSending}
          >
            送信する
          </OfficialCommonBtn>
        </div>
      </div>
    </Wrapper>
  )
}

export default LpExpenseContact01
